import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../../components/ui/button";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import { Checkbox } from "../../../components/ui/checkbox";
import { useNavigate } from "react-router-dom";
import {
  Lga,
  Order,
  OrderItem,
  useGetLgasQuery,
  useGetStatesQuery,
} from "../../../store/ordersApiSlice";
import { useIdStore } from "../../../store/customer-store";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetInstallerQuery } from "../../../store/installersApiSlice";
import { formatPrice } from "../../../lib/utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../../../components/ui/form";
import { useForm } from "react-hook-form";
import { TermsSchema } from "../../../types/terms-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Loader2 } from "lucide-react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const Invoice = ({ orderDetails }: { orderDetails: Order | undefined }) => {
  const navigate = useNavigate();
  const [isGenerating, setIsGenerating] = useState(false);
  const pdfContentRef = useRef<HTMLDivElement>(null); // Ref for hidden PDF content
  // const installerId = useIdStore((state) => state.readInstallerId());

  const orderId = useIdStore((state) => state.readOrderId());

  const form = useForm<z.infer<typeof TermsSchema>>({
    resolver: zodResolver(TermsSchema),
    defaultValues: { terms: false },
  });

  const formValues = form.watch();

  const installerId = orderDetails?.installer_uniq_id;
  const { data: installerDetails, isLoading } = useGetInstallerQuery(
    installerId ? { uniq_id: installerId } : skipToken
  );
  const installer = installerDetails?.data;

  const { data: stateData, isLoading: loadingState } = useGetStatesQuery();
  const stateId = useIdStore((state) => state.readStateId());
  const states = stateData?.data || [];
  const stateName = states.find((state) => state.state_id === stateId);

  const { data: lgaData, isLoading: isLoadingLga } = useGetLgasQuery(
    stateId ? { state_id: stateId } : skipToken
  );
  const lgas = lgaData?.data || [];
  const lgaName = lgas.find(
    (lga: Lga) => lga.local_id === orderDetails?.lga_id
  );

  const customerName = useIdStore((state) => state.readCustomerName());

  const totalPrice =
    orderDetails?.order_items?.reduce((total, item: OrderItem) => {
      return total + (parseFloat(item.price) || 0) * (parseInt(item.qty) || 0);
    }, 0) || 0;

  useEffect(() => {
    console.log(formValues.terms);
  }, [formValues.terms]);

  // const handleDownloadPDF = async () => {
  //   const pdfElement = pdfContentRef.current;
  //   if (!pdfElement) return;

  //   setIsGenerating(true);

  //   try {
  //     // Ensure the image is fully loaded before generating the PDF
  //     const imgElement = pdfElement.querySelector("img");
  //     if (imgElement && installer?.image) {
  //       await new Promise((resolve, reject) => {
  //         imgElement.onload = resolve;
  //         imgElement.onerror = reject;
  //         imgElement.src = installer.image; // Reassign src to trigger load if needed
  //         if (imgElement.complete) resolve(null); // If already loaded, resolve immediately
  //       });
  //     }

  //     // Temporarily make the hidden element visible for rendering
  //     pdfElement.style.position = "static";
  //     pdfElement.style.left = "0";

  //     const canvas = await html2canvas(pdfElement, {
  //       scale: 2,
  //       useCORS: true, // Enable CORS for external images
  //       backgroundColor: "#FFFFFF",
  //       logging: true, // Enable logging to debug rendering issues
  //     });

  //     // Restore the hidden state after rendering
  //     pdfElement.style.position = "absolute";
  //     pdfElement.style.left = "-9999px";

  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "mm",
  //       format: "a4",
  //     });

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const scaledWidth = imgWidth * ratio;
  //     const scaledHeight = imgHeight * ratio;

  //     pdf.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
  //     pdf.save(`invoice_${orderDetails?.date || "latest"}.pdf`);
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   } finally {
  //     setIsGenerating(false);
  //   }
  // };

  // const handleDownloadPDF = async () => {
  //   const pdfElement = pdfContentRef.current;
  //   if (!pdfElement) return;

  //   setIsGenerating(true);

  //   try {
  //     // Get the visible invoice’s width
  //     const visibleInvoice = document.getElementById("invoice-content");
  //     const invoiceWidth = visibleInvoice?.offsetWidth || 600; // Fallback width

  //     // Create an off-screen container with constrained width
  //     const offscreenContainer = document.createElement("div");
  //     offscreenContainer.style.position = "absolute";
  //     offscreenContainer.style.left = "-9999px";
  //     offscreenContainer.style.width = `${invoiceWidth}px`; // Match the visible invoice’s width
  //     offscreenContainer.innerHTML = pdfElement.innerHTML;
  //     document.body.appendChild(offscreenContainer);

  //     const imgElement = offscreenContainer.querySelector("img");
  //     if (imgElement && installer?.image) {
  //       await new Promise((resolve, reject) => {
  //         imgElement.onload = resolve;
  //         imgElement.onerror = reject;
  //         imgElement.src = installer.image;
  //         if (imgElement.complete) resolve(null);
  //       });
  //     }

  //     const canvas = await html2canvas(offscreenContainer, {
  //       scale: 2,
  //       useCORS: true,
  //       backgroundColor: "#FFFFFF",
  //       logging: true,
  //     });

  //     document.body.removeChild(offscreenContainer);

  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "mm",
  //       format: "a4",
  //     });

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const scaledWidth = imgWidth * ratio;
  //     const scaledHeight = imgHeight * ratio;

  //     // Add the invoice image to the PDF
  //     pdf.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);

  //     // Add a black border around the content
  //     pdf.setLineWidth(0.5); // Set border thickness (in mm)
  //     pdf.setDrawColor(0, 0, 0); // Set color to black (RGB: 0, 0, 0)
  //     pdf.rect(5, 5, pdfWidth - 10, pdfHeight - 10, "S"); // Draw rectangle (x, y, width, height, style: "S" for stroke)

  //     // Add "Invoice" text on top of the installer image
  //     pdf.setFontSize(32); // Match the UI font size
  //     pdf.setFont("helvetica", "bold"); // Use bold Helvetica to match the UI
  //     pdf.setTextColor(0, 0, 0); // Set text color to black (RGB: 0, 0, 0)
  //     const text = "Invoice";
  //     const xPosition = 10; // Center the text horizontally
  //     const yPosition = 20; // Adjust this value to position it over the image (in mm from top)
  //     pdf.text(text, xPosition, yPosition);

  //     // Save the PDF
  //     pdf.save(`invoice_${orderDetails?.date || "latest"}.pdf`);
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   } finally {
  //     setIsGenerating(false);
  //   }
  // };

  // const handleDownloadPDF = async () => {
  //   const pdfElement = pdfContentRef.current;
  //   if (!pdfElement) return;

  //   setIsGenerating(true);

  //   try {
  //     const imgElement = pdfElement.querySelector("img");
  //     if (imgElement && installer?.image) {
  //       await new Promise((resolve, reject) => {
  //         imgElement.onload = resolve;
  //         imgElement.onerror = reject;
  //         imgElement.src = installer.image;
  //         if (imgElement.complete) resolve(null);
  //       });
  //     }

  //     // Temporarily adjust styles but keep it off-screen
  //     pdfElement.style.position = "absolute";
  //     pdfElement.style.left = "0";
  //     pdfElement.style.top = "-9999px"; // Move it above the viewport

  //     const canvas = await html2canvas(pdfElement, {
  //       scale: 2,
  //       useCORS: true,
  //       backgroundColor: "#FFFFFF",
  //       logging: true,
  //     });

  //     // Restore original styles
  //     pdfElement.style.position = "absolute";
  //     pdfElement.style.left = "-9999px";
  //     pdfElement.style.top = "0";

  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "mm",
  //       format: "a4",
  //     });

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const scaledWidth = imgWidth * ratio;
  //     const scaledHeight = imgHeight * ratio;

  //     pdf.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);

  //     // Add a black border around the content
  //     pdf.setLineWidth(0.5); // Set border thickness (in mm)
  //     pdf.setDrawColor(0, 0, 0); // Set color to black (RGB: 0, 0, 0)
  //     pdf.rect(5, 5, pdfWidth - 10, pdfHeight - 10, "S"); // Draw rectangle (x, y, width, height, style: "S" for stroke)

  //     // Add "Invoice" text on top of the installer image
  //     pdf.setFontSize(32); // Match the UI font size
  //     pdf.setFont("helvetica", "bold"); // Use bold Helvetica to match the UI
  //     pdf.setTextColor(0, 0, 0); // Set text color to black (RGB: 0, 0, 0)
  //     const text = "Invoice";
  //     const xPosition = 10; // Center the text horizontally
  //     const yPosition = 20; // Adjust this value to position it over the image (in mm from top)
  //     pdf.text(text, xPosition, yPosition);

  //     pdf.save(`invoice_${orderDetails?.date || "latest"}.pdf`);
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   } finally {
  //     setIsGenerating(false);
  //   }
  // };

  const handleDownloadPDF = async () => {
    const pdfElement = pdfContentRef.current;
    if (!pdfElement) return;

    setIsGenerating(true);

    try {
      // Define a fixed width for the off-screen container (e.g., A4-like proportions)
      const a4WidthMm = 210; // A4 width in mm
      const a4HeightMm = 297; // A4 height in mm
      const scaleFactor = 2; // Same as html2canvas scale
      const renderWidthPx = a4WidthMm * 2.83 * scaleFactor; // Convert mm to px (1mm ≈ 2.83px at 72dpi) and apply scale

      // Create an off-screen container with fixed width
      const offscreenContainer = document.createElement("div");
      offscreenContainer.style.position = "absolute";
      offscreenContainer.style.left = "-9999px";
      offscreenContainer.style.width = `${renderWidthPx}px`; // Fixed width for consistency
      offscreenContainer.style.padding = "20px"; // Add padding to mimic the UI
      offscreenContainer.innerHTML = pdfElement.innerHTML;
      document.body.appendChild(offscreenContainer);

      const imgElement = offscreenContainer.querySelector("img");
      if (imgElement && installer?.image) {
        await new Promise((resolve, reject) => {
          imgElement.onload = resolve;
          imgElement.onerror = reject;
          imgElement.src = installer.image;
          if (imgElement.complete) resolve(null);
        });
      }

      const canvas = await html2canvas(offscreenContainer, {
        scale: scaleFactor, // Consistent scaling
        useCORS: true,
        backgroundColor: "#FFFFFF",
        logging: true,
      });

      document.body.removeChild(offscreenContainer);

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth(); // 210mm for A4
      const pdfHeight = pdf.internal.pageSize.getHeight(); // 297mm for A4
      const imgWidth = canvas.width; // Width in pixels
      const imgHeight = canvas.height; // Height in pixels

      // Calculate scaling to fit the content within the PDF page
      const ratio = Math.min(
        pdfWidth / (imgWidth / scaleFactor / 2.83),
        pdfHeight / (imgHeight / scaleFactor / 2.83)
      );
      const scaledWidth = (imgWidth / scaleFactor / 2.83) * ratio; // Convert px to mm and scale
      const scaledHeight = (imgHeight / scaleFactor / 2.83) * ratio; // Convert px to mm and scale

      // Center the image horizontally
      const xOffset = (pdfWidth - scaledWidth) / 2; // Calculate horizontal offset to center
      const yOffset = 0; // Start from the top (adjust if needed)

      // Add the invoice image to the PDF, centered
      pdf.addImage(imgData, "PNG", xOffset, yOffset, scaledWidth, scaledHeight);

      // Add a black border around the content
      pdf.setLineWidth(0.5); // Set border thickness (in mm)
      pdf.setDrawColor(0, 0, 0); // Set color to black (RGB: 0, 0, 0)
      pdf.rect(5, 5, pdfWidth - 10, pdfHeight - 10, "S"); // Draw rectangle (x, y, width, height, style: "S" for stroke)

      // Add "Invoice" text on top left over the installer image
      pdf.setFontSize(20); // Match the UI font size
      pdf.setFont("helvetica", "bold"); // Use bold Helvetica to match the UI
      pdf.setTextColor(0, 0, 0); // Set text color to black (RGB: 0, 0, 0)
      const text = "Invoice";
      const subText = `Order Id: ${orderId}`;
      const xPosition = 10; // Fixed position 10mm from the left edge
      const yPosition = 20; // Vertical position (adjust if needed)

      pdf.text(text, xPosition, yPosition);
      // Add subtext with reduced font size
      pdf.setFontSize(12); // Reduced font size for subtext (e.g., 12pt)
      pdf.setFont("helvetica", "normal"); // Optional: switch to normal weight for subtext
      pdf.text(subText, xPosition, 26); // Same xPosition, adjusted yPosition

      // Save the PDF
      pdf.save(`invoice_${orderDetails?.date || "latest"}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const renderInvoiceContent = (isPdfClone = false) => (
    <div
      id={isPdfClone ? "invoice-content-clone" : "invoice-content"}
      className={`mt-6 md:mt-4 w-full sm:w-[60%] md:w-[70%] lg:w-[75%] mx-auto flex flex-col ${
        isPdfClone ? "bg-white text-black" : "bg-[#DEEDCC33] text-white"
      } rounded-3xl pt-[30px] pb-[10px] px-[16px] md:p-[35px] md:pb-[15px]`}
    >
      <div>
        <div className="flex mb-2 flex-col space-y-1 items-center justify-center">
          {!installer?.image ? (
            <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
          ) : (
            <img
              src={installer?.image}
              alt="installer thumbnail"
              className="rounded-full h-16 w-16"
              crossOrigin="anonymous"
            />
          )}

          <div
            className={`${
              isPdfClone ? "text-black" : "text-white"
            } flex items-center space-x-2`}
          >
            <p className="text-base item-name">{installer?.name}</p>
            {","}
            <span className="text-base item-name">{installer?.state_name}</span>
          </div>
        </div>

        <div className="pb-3 border-b border-dashed border-[#808080] w-full flex flex-col space-y-2">
          <div className="w-full flex items-center justify-between">
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm font-[400] item-name`}
            >
              Date & Time
            </p>
            <p
              className={`font-medium ${
                isPdfClone ? "text-black" : "text-white"
              } text-base text-right item-name`}
            >
              {orderDetails?.date}
            </p>
          </div>
          <div className="w-full flex items-center justify-between">
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm font-[400] item-name`}
            >
              Customer Name
            </p>
            <p
              className={`font-medium ${
                isPdfClone ? "text-black" : "text-white"
              } text-base text-right item-name`}
            >
              {customerName}
            </p>
          </div>
          <div className="w-full flex items-center justify-between">
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm font-[400] item-name`}
            >
              Location
            </p>
            <p
              className={`font-medium ${
                isPdfClone ? "text-black" : "text-white"
              } text-base text-right item-name`}
            >
              {`${lgaName?.name}, ${stateName?.name}`}
            </p>
          </div>
        </div>

        <div className="py-3 border-b border-dashed border-[#808080] w-full flex flex-col space-y-2">
          <div className="w-full flex items-center justify-between">
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm font-[400] item-name`}
            >
              Package
            </p>
            <p
              className={`flex items-center space-x-1 font-medium ${
                isPdfClone ? "text-black" : "text-white"
              } text-base text-right`}
            >
              <span
                className={`${
                  isPdfClone ? "text-green-600" : "text-[#3EB555]"
                } item-name`}
              >
                Optimal:
              </span>
              <span>{formatPrice(orderDetails?.amount ?? 0)}</span>
            </p>
          </div>

          <div className="grid grid-cols-4 w-full">
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm text-left item-name`}
            >
              Item
            </p>
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm text-left item-name`}
            >
              Quantity
            </p>
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm text-left item-name`}
            >
              Rate
            </p>
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm text-left item-name`}
            >
              Price
            </p>
          </div>

          {orderDetails?.order_items.map((item, idx) => (
            <div key={idx} className="item-name grid grid-cols-4 w-full">
              <p
                className={`${
                  isPdfClone ? "text-black" : "text-white"
                } text-left item-name`}
              >
                {item.name}
              </p>
              <p
                className={`${
                  isPdfClone ? "text-black" : "text-white"
                } text-left item-name`}
              >
                {item.qty}
              </p>
              <p
                className={`${
                  isPdfClone ? "text-black" : "text-white"
                } text-left item-name`}
              >
                {formatPrice(item.price ?? 0)}
              </p>
              <p
                className={`${
                  isPdfClone ? "text-black" : "text-white"
                } text-left item-name`}
              >
                {formatPrice(parseFloat(item.qty) * parseFloat(item.price))}
              </p>
            </div>
          ))}
        </div>

        <div className="py-3 border-b border-dashed border-[#808080] w-full flex flex-col space-y-2">
          <div className="w-full flex items-center justify-between">
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm font-[400] item-name`}
            >
              Mode of Payment
            </p>
            <p
              className={`font-medium ${
                isPdfClone ? "text-black" : "text-white"
              } text-base text-right item-name`}
            >
              {orderDetails?.payment_type === "1" ? "One-time" : "Installment"}
            </p>
          </div>
        </div>

        <div className="pt-3 w-full flex flex-col space-y-2">
          <div className="w-full flex items-center justify-between">
            <p
              className={`${
                isPdfClone ? "text-gray-600" : "text-[#B1B1B1]"
              } text-sm font-[400] item-name`}
            >
              Total Amount in NGN
            </p>
            <p
              className={`text-[20px] font-bold ${
                isPdfClone ? "text-black" : "text-white"
              } text-base text-right item-name`}
            >
              {formatPrice(totalPrice || 0)}
            </p>
          </div>
        </div>

        <div className="mb-auto mt-5">
          <p
            className={`italic text-[14px] ${
              isPdfClone ? "text-gray-500" : "text-[#cccccc]"
            } item-name`}
          >
            Powered By Power Ring
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <MaxWidthWrapper>
      <div className="w-full printable-invoice">
        <div>
          <h1 className="text-[32px] font-bold text-white">Invoice</h1>
          {renderInvoiceContent(false)} {/* Visible UI version */}
          <div
            ref={pdfContentRef}
            style={{ position: "absolute", left: "-9999px" }}
          >
            {renderInvoiceContent(true)} {/* Hidden PDF version */}
          </div>
          <div className="flex flex-col relative space-y-3 mt-4 w-full md:w-[50%] mx-auto">
            <Form {...form}>
              <form className="space-y-6">
                <FormField
                  control={form.control}
                  name="terms"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel className="peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-white">
                          I accept the terms and agreement of PowerRing
                        </FormLabel>
                      </div>
                    </FormItem>
                  )}
                />
              </form>
            </Form>
            <div className="no-print w-full relative">
              <div className="w-full">
                <Button
                  onClick={() => navigate("/make-payment")}
                  className="text-black w-full bg-[#3EB555] hover:bg-[#359C4A]"
                  disabled={!formValues.terms}
                >
                  Proceed
                </Button>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  disabled={!formValues.terms || isGenerating}
                  // disabled={!formValues.terms || isGenerating || isLoading}
                  variant={"link"}
                  onClick={handleDownloadPDF}
                  className="text-[#3EB555] hover:text-[#359C4A]"
                >
                  {isGenerating ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Generating...
                    </>
                  ) : (
                    "Download Invoice as PDF"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Invoice;
