import React, { useState } from "react";
import MaxWidthWrapper from "./max-width-wrapper";

const ContactUs = ({
  email,
  phone,
  address,
}: {
  email: string;
  phone: string;
  address: string;
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    const { name, email: userEmail, message } = formData;

    // Construct the mailto URL
    const mailtoUrl = `mailto:${email}?subject=Contact Us&body=Name: ${encodeURIComponent(
      name
    )}%0AEmail: ${encodeURIComponent(
      userEmail
    )}%0AMessage: ${encodeURIComponent(message)}`;

    // Open the mail client
    window.location.href = mailtoUrl;
  };

  return (
    <section id="contact-us" className="mt-20 py-8   bg-[#f7f7f7]">
      <MaxWidthWrapper>
        <div className="mx-auto w-full">
          <div className="mb-4">
            <div className="mb-6 max-full text-left md:mx-auto md:mb-12">
              <p className="text-3xl font-bold tracking-tight text-[#1e1e1e] sm:text-4xl">
                Get in Touch
              </p>
              <p className="mx-auto mt-3 w-full text-[0.9rem] text-[#555555]">
                Learn more about us, our services or any other enquiries
              </p>
            </div>
          </div>
          <div className=" flex  justify-center">
            <div className="grid md:grid-cols-2 ">
              <div className="h-full pr-6">
                <ul className="mb-6 md:mb-0">
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-[#012B27] text-gray-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2  font-medium leading-6 text-[#1e1e1e] ">
                        Our Address
                      </h3>
                      <p className="text-[#555555] text-[0.9rem]">{address}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="h-fit max-w-7xl" id="form">
                <ul className="mb-6 md:mb-0">
                  <li className="flex">
                    <div className="flex h-10 w-10 items-center justify-center rounded bg-[#012B27] text-gray-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                        <path d="M12 7v5l3 3"></path>
                      </svg>
                    </div>
                    <div className="ml-4 mb-4">
                      <h3 className="mb-2  font-medium leading-6 text-[#1e1e1e] ">
                        Working hours
                      </h3>
                      <p className="text-[#555555] text-[0.9rem]  ">
                        Monday - Friday: 09:00 AM - 5:00 PM
                      </p>
                      <p className="text-[#555555] text-[0.9rem] ">
                        Saturday 09:00 AM - 4:00 PM
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
    </section>
  );
};

export default ContactUs;
